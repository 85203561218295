import { Container } from '@mui/material';
import { useWindowSize } from 'hooks';
import merge from 'utils/merge';

const images = [
  {
    image: require('assets/images/cards/Dragon-Athena.Card-01.webp'),
    zIndex: 1,
    isMobile: false,
  },
  {
    image: require('assets/images/cards/Dragon-Athena.Card-02.webp'),
    zIndex: 2,
    isMobile: true,
  },
  {
    image: require('assets/images/cards/Dragon-Athena.Card-03.webp'),
    zIndex: 3,
    isMobile: true,
  },
  {
    image: require('assets/images/cards/Dragon-Athena.Card-04.webp'),
    zIndex: 2,
    isMobile: true,
  },
  {
    image: require('assets/images/cards/Dragon-Athena.Card-05.webp'),
    zIndex: 1,
    isMobile: false,
  },
];

const Utility = () => {
  const { isMobile } = useWindowSize();

  const filterImages = isMobile ? images.filter((image) => image.isMobile) : images;

  return (
    <div
      style={{ background: `url(${require('assets/images/Cover.NFT-Utility.webp')}) no-repeat top center / cover` }}
      className='py-40'
    >
      <Container className='flex flex-col items-center gap-20 py-20 relative'>
        <img
          src={require('assets/icons/Icon.Snow.png')}
          className='absolute right-[-20px] top-0 go-jump hidden lg:block'
        />
        <img
          src={require('assets/icons/Icon.Snow.Left.png')}
          className='absolute right-10 bottom-[-120px] xl:left-[-20px] md:bottom-0 go-jump z-99'
        />

        <div className='max-w-[650px] text-center' data-aos='fade-up' data-aos-duration='1000'>
          <div
            className='text-2xl sm:text-4xl font-bold font-title'
            style={{
              background: 'linear-gradient(180deg, #FFCE50 0%, #FFEFC6 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            NFT UTILITY
          </div>
          <div className='font-light mt-6 text-lg'>
            Become a member of the Dragon Community by owning unique dragons. These dragons are easily integratable into
            other projects with Unity compatibility, and owning Dragons comes with exclusive benefits and support from a
            sustainable NFT-based economic mechanism. Join now and be a part of this exciting community!
          </div>
        </div>
        <div className='flex justify-center items-end space-x-[-20px] sm:space-x-[-40px] mb-20'>
          {filterImages.map((item, index) => (
            <img
              key={index}
              src={item.image}
              className={merge(
                'w-[120px] sm:w-[200px] md:w-[160px] xl:w-[260px] rounded-[12px] hover:z-10 cursor-pointer hover:scale-105 duration-100',
                {
                  'hidden sm:block': !item.isMobile,
                },
              )}
              style={{
                transform: `rotate(${(!isMobile ? -8 : -4) + index * 4}deg)`,
                zIndex: item.zIndex,
                marginBottom: -item.zIndex * 16,
              }}
            />
          ))}
        </div>
      </Container>
    </div>
  );
};

export default Utility;
