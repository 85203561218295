import { List, ListItemButton, ListItemText, styled } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { privateRoute } from 'routes';

const StyledListItem = styled(ListItemButton)({
  borderRadius: 9999,
  minWidth: 150,
  fontFamily: 'Mitr !important',
  color: 'var(--color-primary-main) !important',
  '&.Mui-selected, &:hover': {
    backgroundColor: '#EEB73D26 !important',
  },
  textAlign: 'center',
});

const MenuItem = ({ path, name, className }: { path: string; name?: string; className?: string }) => {
  const location = useLocation();

  return (
    <Link to={path}>
      <StyledListItem selected={location.pathname.startsWith(path)} className={className}>
        <ListItemText disableTypography className='font-title text-sm font-semibold'>
          {name}
        </ListItemText>
      </StyledListItem>
    </Link>
  );
};

const Menu = () => {
  const { leaderBoard } = privateRoute;

  return (
    <List className='sm:flex gap-x-4 hidden'>
      <MenuItem {...leaderBoard} />
    </List>
  );
};

export default Menu;
