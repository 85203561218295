export const BROWSER = {
  CHROME: 'CHROME',
  FIREFOX: 'FIREFOX',
  SAFARI: 'SAFARI',
  IE: 'IE',
  OTHER: 'OTHER',
};

export const isEmail = (text) => {
  return /^[\w.-]{1,}@[\w-]{2,}(.[\w-]{2,}){1,}$/.test(text);
};

export const isUsername = (text) => {
  return /^[\w]{6,}$/.test(text);
};

export const detectBrowser = () => {
  const userAgent = navigator.userAgent;
  if (userAgent.indexOf('Chrome') !== -1) {
    return BROWSER.CHROME;
  } else if (userAgent.indexOf('Safari') !== -1) {
    return BROWSER.SAFARI;
  } else if (userAgent.indexOf('Firefox') !== -1) {
    return BROWSER.FIREFOX;
  } else if (userAgent.indexOf('MSIE') !== -1 || !!document.documentMode === true) {
    return BROWSER.IE;
  } else {
    return BROWSER.OTHER;
  }
};
