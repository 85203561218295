import { IconButton, IconButtonProps } from '@mui/material';

const CloseButton = (props: IconButtonProps) => {
  return (
    <IconButton className='absolute top-[14px] right-2' color='info' {...props}>
      <img src={require(`../assets/icons/close.svg`).default} />
    </IconButton>
  );
};

export default CloseButton;
