import { Container, Grid } from '@mui/material';

const partners1 = [
  require('assets/images/partners/Image.Light-House.png'),
  require('assets/images/partners/Image.Black-Mamba.png'),
  require('assets/images/partners/Image.Gempad.png'),
  require('assets/images/partners/Image.Wings.png'),
  require('assets/images/partners/Image.UG.png'),
  require('assets/images/partners/Image.Arbitrum-Insider.png'),
];

const partners2 = [
  require('assets/images/partners/Image.Coinf.png'),
  require('assets/images/partners/Image.UB.png'),
  require('assets/images/partners/Image.s6k.png'),
  require('assets/images/partners/Image.Megala.png'),
  require('assets/images/partners/Image.VB.png'),
];

const partners3 = [
  require('assets/images/partners/Image.Tgk.png'),
  require('assets/images/partners/Image.Crypto-Checker.png'),
  require('assets/images/partners/Image.HTR.png'),
  require('assets/images/partners/Image.Cointv.png'),
  require('assets/images/partners/Image.Beng-Gaming.png'),
  require('assets/images/partners/Image.Gotbit.png'),
];

const Partner = () => {
  return (
    <div
      style={{
        background: `url(${require(`assets/images/Cover.Partners.png`)}) no-repeat center / cover`,
      }}
      className='border-t-[6px] border-[#ad9f8c]'
    >
      <Container
        className='flex flex-col justify-center items-center py-20 md:py-40'
        maxWidth='lg'
        data-aos='fade-up'
        data-aos-duration='1000'
      >
        <div
          className='text-2xl sm:text-4xl font-title font-bold text-center mb-12'
          style={{
            background: 'linear-gradient(180deg, #FFCE50 0%, #FFEFC6 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          PARTNERS
        </div>
        <Grid container spacing={2} justifyContent='center' alignItems='center' className='xl:px-24'>
          {partners1.map((item) => (
            <Grid item xs={4} md={2}>
              <img src={item} className='mx-auto hover:scale-125' />
            </Grid>
          ))}
        </Grid>
        <Grid container rowSpacing={2} justifyContent='center' alignItems='center' className='my-16'>
          {partners2.map((item) => (
            <Grid item xs={6} sm={4} md={2.4}>
              <img src={item} className='mx-auto hover:scale-125' />
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={2} justifyContent='center' alignItems='center'>
          {partners3.map((item) => (
            <Grid item xs={4} md={2}>
              <img src={item} className='mx-auto hover:scale-125' />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default Partner;
