import { Container } from '@mui/material';
import { BROWSER } from 'utils/validate';

const Gambling = ({ browser }: { browser: string }) => {
  return (
    <div
      style={{ background: `url(${require('assets/images/Cover.Gamblefi.webp')}) no-repeat center / cover` }}
      className='relative min-h-[842px]'
    >
      <Container maxWidth='lg' className='flex flex-col items-center justify-center gap-16 py-32'>
        <div className='max-w-[660px] text-center' data-aos='fade-up' data-aos-duration='1000'>
          <div
            className='text-2xl sm:text-4xl font-title font-bold'
            style={{
              background: 'linear-gradient(180deg, #FFCE50 0%, #FFEFC6 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            EXCITING GAMBLEFI
          </div>
          <div className='font-light mt-8 text-lg'>
            Simulated by Coinflip head-and-tail games, Dragon Arena allows players to bet on battles between dragons of
            their choice. Using advanced graphics, the game offers both visually stunning and strategically engaging
            gameplay with rewards for winners. Choose your dragon and let the battles begin!
          </div>
        </div>

        <video
          className='absolute bottom-[-80px] z-10'
          {...{ autoPlay: true, muted: true, loop: true }}
          src={require(`assets/videos/${browser === BROWSER.SAFARI ? 'Gambling-Safari.mp4' : 'Gambling-Chrome.webm'}`)}
        />

        <img src={require('assets/images/mountains/Mountain-Gambling-Left.png')} className='absolute bottom-0 left-0' />
        <img
          src={require('assets/images/mountains/Mountain-Gambling-Right.png')}
          className='absolute bottom-0 right-0'
        />
      </Container>
      <img
        src={require('assets/icons/Icon.Coin-1.webp')}
        className='absolute top-1/2 left-24 go-jump hidden xl:block'
      />
    </div>
  );
};

export default Gambling;
