import { Button, Container, IconButton } from '@mui/material';
import { useWindowSize } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import { privateRoute } from 'routes';
import { copy } from 'utils/common';
import { BROWSER } from 'utils/validate';

const Banner = ({ browser }: { browser: string }) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [isShow, setIsShow] = useState(true);
  const { isLgScreen } = useWindowSize();

  const handleScroll = () => {
    if (divRef.current) {
      window.scrollY > divRef.current.offsetHeight - 400 ? setIsShow(false) : setIsShow(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const cloudRightProps = useSpring({
    from: { transform: 'translateX(0%)' },
    to: {
      transform: isShow ? 'translateX(0%)' : 'translateX(100%)',
    },
    config: {
      friction: 80,
    },
  });

  const cloudLeftProps = useSpring({
    from: { transform: 'translateX(0%)' },
    to: {
      transform: isShow ? 'translateX(0%)' : 'translateX(-100%)',
    },
    config: {
      friction: 80,
    },
  });

  return (
    <div className='relative banner-container' ref={divRef}>
      <img
        src={require(`assets/images/${isLgScreen ? 'mobiles/Top.Background.webp' : 'Top.Background.webp'}`)}
        className='w-screen'
      />
      <Container className='absolute inset-0 flex items-center'>
        <div className='flex flex-col lg:flex-row justify-evenly items-center w-full mt-[-100px]'>
          <div className='flex flex-col justify-center items-center z-10'>
            <img src={require('assets/icons/Logo.App.webp')} className='w-[252px] sm:w-auto mt-16 sm:mt-0' />
            <div
              className='text-lg sm:text-3xl xl:text-4xl font-title font-bold text-center space-y-2'
              style={{ textShadow: '4px 6px 8px rgba(0, 0, 0, 0.6)' }}
            >
              <div>LET'S OWN AND PLAY!</div>
              <div>YOUR LIFESTYLE - YOUR DRAGON</div>
            </div>
          </div>
          <Link to={privateRoute.leaderBoard.path}>
            <Button
              variant='text'
              className='rounded-full w-[150px] h-[50px] font-title font-semibold text-white hover:brightness-75 block sm:hidden my-4'
              style={{ background: `url(${require('assets/icons/Icon.Button.png')})` }}
            >
              Leaderboard
            </Button>
          </Link>
          <video
            className='w-[320px] h-[320px] sm:w-[458px] sm:h-[458px] z-10'
            {...{ autoPlay: true, muted: true, loop: true }}
            src={require(`assets/videos/${
              browser === BROWSER.SAFARI ? 'Banner.Dragon-Safari.mp4' : 'Banner.Dragon-Chrome.webm'
            }`)}
          />
        </div>
      </Container>

      <animated.div style={{ ...cloudLeftProps, position: 'absolute', top: 0, left: 0, zIndex: 2 }}>
        <img src={require('assets/images/clouds/Cloud-Left-1.png')} className='run-left' />
      </animated.div>

      <animated.div style={{ ...cloudLeftProps, position: 'absolute', bottom: 120, left: 0, zIndex: 2 }}>
        <img src={require('assets/images/clouds/Cloud-Left-2.png')} className='run-left hidden lg:block' />
      </animated.div>

      <animated.div style={{ ...cloudLeftProps, position: 'absolute', bottom: 0, left: 0, zIndex: 2 }}>
        <img src={require('assets/images/clouds/Cloud-Left-3.png')} className='run-right' />
      </animated.div>

      <animated.div style={{ ...cloudRightProps, position: 'absolute', top: 0, right: 0, zIndex: 2 }}>
        <img src={require('assets/images/clouds/Cloud-Right-1.png')} className='run-right' />
      </animated.div>

      <animated.div style={{ ...cloudRightProps, position: 'absolute', bottom: 240, right: 0, zIndex: 2 }}>
        <img src={require('assets/images/clouds/Cloud-Right-2.png')} className='run-right hidden lg:block' />
      </animated.div>

      <animated.div style={{ ...cloudRightProps, position: 'absolute', bottom: 80, right: 0, zIndex: 2 }}>
        <img src={require('assets/images/clouds/Cloud-Right-3.png')} className='run-right hidden lg:block' />
      </animated.div>

      <animated.div style={{ ...cloudRightProps, position: 'absolute', bottom: 0, right: 0, zIndex: 2 }}>
        <img src={require('assets/images/clouds/Cloud-Right-4.png')} className='run-right' />
      </animated.div>

      <img
        src={require('assets/images/mountains/Mountain-Left.webp')}
        className='absolute bottom-0 left-0 z-[1] hidden lg:block'
      />
      <img
        src={require('assets/images/mountains/Mountain-Right.webp')}
        className='absolute bottom-0 right-0 z-[1] hidden lg:block'
      />

      <div className='bg-primary-main fixed w-full top-[80px] py-2 px-5 justify-center items-center space-x-2 flex flex-col md:flex-row z-50'>
        <div className='text-[#111315] text-sm sm:text-base text-center font-medium '>
          Official $DRA Smart Contract: 0x93c157932E5649E794Df8999714af8690226A1c9
        </div>
        <div className='flex items-center'>
          <IconButton onClick={() => copy('0x93c157932E5649E794Df8999714af8690226A1c9')} title='Copy'>
            <img src={require('assets/icons/Icon.Copy.png')} />
          </IconButton>
          <a
            href='https://arbiscan.io/token/0x93c157932E5649E794Df8999714af8690226A1c9'
            target='_blank'
            rel='noreferrer'
          >
            <img src={require('assets/icons/Icon.Detail-Black.png')} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Banner;
