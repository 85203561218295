import { Container } from '@mui/material';
import { useWindowSize } from 'hooks';
import { BROWSER } from 'utils/validate';

const Story = ({ browser }: { browser: string }) => {
  const { isMobile } = useWindowSize();
  return (
    <div
      style={{
        background: `#040f20 url(${require(`assets/images/${
          isMobile ? 'mobiles/Cover.Story.webp' : 'Cover.Story.webp'
        }`)}) no-repeat center / cover`,
      }}
      className='relative'
    >
      <Container
        maxWidth='lg'
        className='min-h-[960px] flex flex-col lg:flex-row justify-center items-center gap-16 xl:gap-32 py-20'
      >
        <div className='relative'>
          <img src={require('assets/images/Story.Frame.png')} />
          <video
            className='absolute inset-0 m-auto w-[320px] sm:w-[554px] lg:w-[432px] xl:w-[554px]'
            {...{ autoPlay: true, muted: true, loop: true }}
            src={require(`assets/videos/The-Story.mp4`)}
          />
          <video
            className='absolute w-[200px] sm:w-auto right-0 md:right-[-80px] bottom-8'
            {...{ autoPlay: true, muted: true, loop: true }}
            src={require(`assets/videos/${
              browser === BROWSER.SAFARI ? 'The-Story.Dragon-Safari.mp4' : 'The-Story.Dragon-Chrome.webm'
            }`)}
          />
        </div>
        <div className='max-w-[388px] text-center' data-aos='fade-up' data-aos-duration='1000'>
          <div className='flex justify-center'>
            <img src={require('assets/images/Line-Decor.png')} className='w-56' />
          </div>
          <div className='my-10'>
            <div
              className='text-4xl font-title font-bold'
              style={{
                background: 'linear-gradient(180deg, #FFCE50 0%, #FFEFC6 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              THE STORY
            </div>
            <div className='font-light font-family mt-6 text-lg'>
              A cool Dragon has been waiting to meet you. Give them a name and be sure to provide them with the best
              care. Who knows? You two may become best friends!
            </div>
          </div>
          <div className='flex justify-center'>
            <img src={require('assets/images/Line-Decor.png')} className='w-56' />
          </div>
        </div>
      </Container>
      <img
        src={require('assets/images/Clouds-The-Story.webp')}
        className='absolute bottom-[-60px] xl:bottom-[-160px] left-0 min-w-[200vw] go-left z-10'
      />
    </div>
  );
};

export default Story;
