import { Home } from 'views/Home';
import { LeaderBoard } from 'views/LeaderBoard';
import { DetailTreasury, Treasury } from 'views/Treasury';

const publicRoute = {
  home: {
    path: '/',
    name: 'Home',
    component: Home,
  },
  treasury: {
    path: '/treasury',
    name: 'Treasury',
    component: Treasury,
  },
  treasuryDetail: {
    path: '/treasury/:id',
    name: 'Detail Treasury',
    component: DetailTreasury,
  },
  leaderBoard: {
    path: '/leader-board',
    name: 'Leaderboard',
    component: LeaderBoard,
  },
};

export default publicRoute;
