import { Container } from '@mui/material';
import { useWindowSize } from 'hooks';

const Feature = () => {
  const { isMobile } = useWindowSize();
  return (
    <div
      style={{
        background: `url(${require(`assets/images/${
          isMobile ? 'mobiles/Cover.Feature.webp' : 'Cover.Feature.webp'
        }`)}) no-repeat center / cover`,
      }}
      className='relative'
    >
      <Container
        className='flex flex-col justify-center items-center py-20 md:py-60'
        maxWidth='lg'
        data-aos='fade-up'
        data-aos-duration='1000'
      >
        <div className='flex items-center justify-center mb-6'>
          <img src={require('assets/images/Logo.Dragon-Arena.png')} />
          <img src={require('assets/images/Image.X.png')} className='mx-6' />
          <img src={require('assets/images/Logo.Arbitrum.png')} className='w-[120px] sm:w-auto' />
        </div>

        <div
          className='text-2xl sm:text-4xl font-title font-bold text-center'
          style={{
            background: 'linear-gradient(180deg, #FFCE50 0%, #FFEFC6 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          DRAGON ARENA ON ARBITRUM
        </div>
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-8 mt-8'>
          <div className='max-w-[420px] text-left sm:hover:scale-110 ease-in duration-300'>
            <div className='font-bold text-xl sm:text-2xl mb-2'>Fast transaction speed</div>
            <div className='font-light'>
              With Arbitrum's Layer 2 solution, the transaction confirmation time is significantly shortened and can
              reach almost real-time speed.
            </div>
          </div>
          <div className='max-w-[420px] text-left sm:hover:scale-110 ease-in duration-300'>
            <div className='font-bold text-xl sm:text-2xl mb-2'>Low transaction fees</div>
            <div className='font-light'>
              With Arbitrum, gas fees in game development and game transactions on the blockchain are significantly
              reduced.
            </div>
          </div>
          <div className='max-w-[420px] text-left sm:hover:scale-110 ease-in duration-300'>
            <div className='font-bold text-xl sm:text-2xl mb-2'>Security and safety</div>
            <div className='font-light'>
              Arbitrum uses modern security technologies such as cryptography to protect the information and assets of
              players in the game.
            </div>
          </div>
          <div className='max-w-[420px] text-left sm:hover:scale-110 ease-in duration-300'>
            <div className='font-bold text-xl sm:text-2xl mb-2'>Easy integration</div>
            <div className='font-light'>
              Arbitrum is built on the Ethereum platform, so integration into the system will be easier compared to
              other blockchain platforms.
            </div>
          </div>
        </div>
      </Container>
      <img
        src={require('assets/icons/Icon.Coin-2.webp')}
        className='absolute top-20 right-20 go-jump hidden md:block'
      />
    </div>
  );
};

export default Feature;
