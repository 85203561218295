import {
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import { CloseButton } from 'components';
import { profileSelector } from 'reducers/profileSlice';
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from '@tanstack/react-query';
import { LoadingButton } from '@mui/lab';
import { airdropTokenContract } from 'contracts';
import { useSnackbar } from 'notistack';
import { getPolygonFee } from 'utils/common';
import { AIRDROP_CONTRACT_ADDRESS, CHAIN_ID } from 'env';
import { walletService } from 'services';

const ClaimFaucetPopup = ({ onClose }: { onClose: () => void }) => {
  const { isLoggedIn, address } = useSelector(profileSelector);
  const { enqueueSnackbar } = useSnackbar();

  const { mutate, isLoading } = useMutation(
    async () => {
      const maxFeeForFast = (await getPolygonFee(CHAIN_ID)) as number;
      await airdropTokenContract(AIRDROP_CONTRACT_ADDRESS!)
        .methods.claim()
        .send({ from: address, gasPrice: maxFeeForFast });
    },
    {
      onError: () => {
        enqueueSnackbar('Somethings went wrong, please try again later', { variant: 'error' });
      },
      onSuccess: () => {
        enqueueSnackbar('Claim token successfully');
        onClose();
      },
    },
  );

  const { data: isClaimed, isFetching } = useQuery(
    ['airdropTokenContract.isClaimed', { address }],
    () => airdropTokenContract(AIRDROP_CONTRACT_ADDRESS!).methods.claimed(address).call(),
    {
      onError: (error: any) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
      enabled: isLoggedIn,
    },
  );

  const handleClaimFaucet = () => {
    mutate();
  };

  return (
    <>
      <DialogTitle className='text-left text-white font-title'>CLAIM FAUCET</DialogTitle>
      <DialogContent dividers>
        <div className='flex flex-col items-center mb-8'>
          <Typography variant='h6' className='font-normal text-center font-title uppercase text-[#FFD437]'>
            Drop in your wallet details
          </Typography>
          <div className='text-white text-sm text-center'>Connect your wallet first to get tokens!</div>
        </div>
        <div className='font-semibold'>
          <div className='text-sm text-[#9B9B9B] mb-1'>Your chain</div>
          <div className='flex items-center space-x-2'>
            <img src={require('assets/icons/Logo.Arbitrum.png')} />
            <span className='text-white'>Arbitrum</span>
          </div>
        </div>
        <FormControl fullWidth className='my-6'>
          <label className='text-[#9B9B9B] text-sm font-semibold'>Your Wallet Address</label>
          <TextField fullWidth InputProps={{ readOnly: true }} value={address} />
        </FormControl>
        <div className='flex justify-center'>
          {isLoggedIn ? (
            isFetching ? (
              <CircularProgress color='primary' size='2rem' />
            ) : !isClaimed ? (
              <LoadingButton
                style={{ background: `url(${require('assets/icons/Icon.Button.png')})` }}
                className='rounded-full w-[150px] h-[50px] font-title font-bold text-white'
                onClick={handleClaimFaucet}
                loading={isLoading}
              >
                Claim ARB
              </LoadingButton>
            ) : (
              <span className='text-[#FF4A4A] text-sm font-semibold'>This wallet has already claimed tokens.</span>
            )
          ) : (
            <Button
              variant='text'
              className='rounded-full w-[150px] h-[50px] font-title font-bold text-white hover:brightness-75 block sm:hidden my-4'
              style={{ background: `url(${require('assets/icons/Icon.Button.png')})` }}
              onClick={() => walletService.connectWallet()}
            >
              Connect Wallet
            </Button>
          )}
        </div>
      </DialogContent>
      <CloseButton onClick={onClose} />
    </>
  );
};

export default ClaimFaucetPopup;
