import { client } from './axios';

const fetchTopWinners = (tournamentId: string | undefined): Promise<TopWinnerType[]> =>
  client.get(`/dragon-arena-apis/api/game-bets/top-points?tournamentId=${tournamentId}`);

const fetchTopRich = (tournamentId: string | undefined): Promise<TopRichType[]> =>
  client.get(`/dragon-arena-apis/api/game-bets/top-volumes?tournamentId=${tournamentId}`);

const getWinRate = ({ address, ...params }: UserGameParams): Promise<TopWinnerType> =>
  client.get(`/dragon-arena-apis/api/game-bets/${address}/user-points`, { params });

const getWinAmount = ({ address, ...params }: UserGameParams): Promise<TopRichType> =>
  client.get(`/dragon-arena-apis/api/game-bets/${address}/user-volumes`, { params });

const fetchTournaments = (): Promise<Tournament> => client.get(`/dragon-arena-apis/api/tournaments`);

export default {
  fetchTopWinners,
  fetchTopRich,
  getWinRate,
  getWinAmount,
  fetchTournaments,
};
