import { ArrowBackIos } from '@mui/icons-material';
import {
  Container,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Spinner, TableRowEmpty } from 'components';
import { useSearch } from 'hooks';
import { useNavigate } from 'react-router-dom';

const DetailTreasury = () => {
  const [, onSearchChange] = useSearch({ page: 1, size: 12 });

  const navigate = useNavigate();

  return (
    <>
      <div
        style={{ background: `url(${require('assets/images/Cover.Treasury.png')}) no-repeat top center / cover` }}
        className='py-28'
      >
        <Container maxWidth='lg' className='flex flex-col items-center justify-center'>
          <div className='flex items-center justify-start gap-x-4 w-full'>
            <IconButton color='inherit' onClick={() => navigate(-1)}>
              <ArrowBackIos color='primary' fontSize='small' />
              <span
                className='text-xl'
                style={{
                  background: 'linear-gradient(180deg, #FFCE50 0%, #FFEFC6 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                Detail
              </span>
            </IconButton>
            <span>Token Release T10</span>
          </div>
          <TableContainer component={Paper}>
            <Spinner loading={false}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>No.</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell className='whitespace-nowrap'>Total Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[].map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ))}
                  <TableRowEmpty visible={!false && [].length === 0} />
                </TableBody>
              </Table>
            </Spinner>
          </TableContainer>

          <div className='flex justify-center'>
            <Pagination page={1} count={1} onChange={(event, value) => onSearchChange({ page: value })} />
          </div>
        </Container>
      </div>
    </>
  );
};

export default DetailTreasury;
