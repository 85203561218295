import {
  Container,
  Grid,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { Spinner, TableRowEmpty } from 'components';
import { useSearch } from 'hooks';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { privateRoute } from 'routes';

const TIME_RANGE_FILTERS = [
  { value: { days: 1 }, label: 'Latest', code: 'LATEST' },
  { value: { days: 7 }, label: 'Last 7 days', code: 'LAST_7_DAYS' },
  { value: { days: 30 }, label: 'Last 30 days', code: 'LAST_30_DAYS' },
];

const Treasury = () => {
  const [, onSearchChange] = useSearch({ page: 1, size: 12 });

  const { control, watch } = useForm({ mode: 'onChange' });

  useEffect(() => {
    const subscription = watch(({ sort }, { name, type }) => {
      if (type) {
        if (name === 'sort') {
          if (sort) {
            onSearchChange({
              fromDate: Math.floor(
                DateTime.now()
                  .minus(TIME_RANGE_FILTERS.find((item) => item.code === sort)?.value ?? { days: 1 })
                  .toSeconds(),
              ),
              toDate: Math.floor(DateTime.now().toSeconds()),
            });
          }
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, onSearchChange]);

  return (
    <div
      style={{ background: `url(${require('assets/images/Cover.Treasury.png')}) no-repeat top center / cover` }}
      className='py-16 md:py-28'
    >
      <Container maxWidth='lg' className='flex flex-col items-center justify-center'>
        <div className='max-w-[720px] text-center'>
          <div
            className='text-2xl sm:text-4xl font-bold font-title'
            style={{
              background: 'linear-gradient(180deg, #FFCE50 0%, #FFEFC6 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            TREASURY
          </div>
          <div className='font-light mt-4'>
            Our Treasury serves as a centralized repository for the various fees generated through the sale of eggs,
            transactions on the Marketplace, and fees associated with the game system.
          </div>
        </div>
        <Grid
          container
          spacing={2}
          className='mt-10 p-4 border-y-[0.5px] border-[#53709B] flex items-center justify-center w-full'
        >
          <Grid item xs={12} lg={4} className='text-center'>
            <div className='text-[#6F767E] font-medium mb-2'>Gambling fees (DRA)</div>
            <div className='text-4xl'>-</div>
          </Grid>
          <Grid item xs={12} lg={4} className='text-center font-medium lg:border-x-[0.5px] border-[#53709B]'>
            <div className='text-[#6F767E]  mb-2'>Marketplace transaction fees (DRA)</div>
            <div className='text-4xl'>-</div>
          </Grid>
          <Grid item xs={12} lg={4} className='text-center'>
            <div className='text-[#6F767E] font-medium  mb-2'>Distribution (DRA)</div>
            <div className='text-4xl'>-</div>
          </Grid>
        </Grid>
        <div
          style={{
            background:
              'linear-gradient(90deg, rgba(255, 252, 252, 0) 0%, rgba(255, 213, 105, 0.2) 50%, rgba(247, 247, 247, 0) 100%)',
          }}
          className='p-4 w-full flex items-center justify-center gap-x-4'
        >
          <img src={require('assets/images/Image.Treasury-Box.png')} />
          <div className='flex flex-col h-full gap-y-2'>
            <div>Treasury (DRA)</div>
            <div
              className='text-4xl font-bold'
              style={{
                background: 'linear-gradient(180deg, #FFCE50 0%, #FFEFC6 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              N/A
            </div>
          </div>
        </div>
        <div style={{ background: '#0C152480' }} className='w-full p-4 md:p-10'>
          <div className='flex items-center justify-between'>
            <div
              className='text-xl font-semibold'
              style={{
                background: 'linear-gradient(180deg, #FFCE50 0%, #FFEFC6 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              Payment History
            </div>
            <Controller
              name='sort'
              defaultValue={TIME_RANGE_FILTERS[0].code}
              control={control}
              render={({ field }) => (
                <TextField select {...field}>
                  {TIME_RANGE_FILTERS.map((item, index) => (
                    <MenuItem key={item.code} value={item.code}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </div>
          <TableContainer component={Paper}>
            <Spinner loading={false}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Total Amount</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[].map((item, index) => (
                    <TableRow key={index}>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <Link to={`${privateRoute.home}`}>
                          <div className='flex whitespace-nowrap items-center gap-x-2'>
                            <span>See Detail</span>
                            <img src={require('assets/icons/Icon.Detail.png')} />
                          </div>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRowEmpty visible={!false && [].length === 0} />
                </TableBody>
              </Table>
            </Spinner>
          </TableContainer>

          <div className='flex justify-center'>
            <Pagination page={1} count={1} onChange={(event, value) => onSearchChange({ page: value })} />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Treasury;
