import { AppBar, Button, Container, Dialog, IconButton, Toolbar } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { profileSelector, signOut } from 'reducers/profileSlice';
import { privateRoute } from 'routes';
import { ClaimFaucetPopup } from 'views/Home/popups';
import { CloseOutlined, Logout, MenuOutlined } from '@mui/icons-material';
import { AppMenu } from 'containers';
import { walletService } from 'services';

const Header = () => {
  const [openModal, setOpenModal] = useState(false);
  const { isLoggedIn } = useSelector(profileSelector);

  const dispatch = useDispatch();
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    setShowMenu(false);
  }, [location]);

  return (
    <>
      <AppBar component='header' className='bg-black/90'>
        <Toolbar className='h-[80px] px-2 md:px-6'>
          <Container maxWidth='xl' className='flex justify-center sm:justify-between items-center px-0'>
            <Link to={privateRoute.home.path}>
              <img src={require('assets/icons/Logo.App.webp')} className='h-[60px]' />
            </Link>

            <div className='flex-1' />

            <div className='flex items-center justify-center gap-4'>
              <AppMenu />
              <div
                className='mobile-nav items-center justify-center gap-4'
                style={{ display: showMenu ? 'flex' : 'none' }}
              >
                <Button
                  variant='text'
                  className='rounded-full w-[150px] h-[50px] font-title font-semibold bg-primary-main lg:bg-inherit text-white lg:text-[#EEB73D]'
                  href='https://docs.dragonarena.games/'
                  target='_blank'
                  rel='noreferrer'
                >
                  Whitepaper
                </Button>
              </div>
              <Button
                variant='text'
                className='rounded-full w-[150px] h-[50px] font-title font-bold items-center text-white hover:brightness-75 hidden md:flex'
                style={{ background: `url(${require('assets/icons/Icon.Button.png')})` }}
                href='https://mainnet.dragonarena.games/'
                target='_blank'
                rel='noreferrer'
              >
                Launch Game
              </Button>
              {isLoggedIn ? (
                <IconButton className='text-[#EEB73D]' onClick={() => dispatch(signOut({}))}>
                  <Logout />
                </IconButton>
              ) : (
                <Button
                  variant='text'
                  className='rounded-full w-[150px] h-[50px] font-title font-bold text-white hover:brightness-75'
                  style={{ background: `url(${require('assets/icons/Icon.Button.png')})` }}
                  onClick={() => walletService.connectWallet()}
                >
                  Connect Wallet
                </Button>
              )}
            </div>

            <IconButton color='secondary' className='hamburger-icon ml-3' onClick={() => setShowMenu((prev) => !prev)}>
              {showMenu ? <CloseOutlined /> : <MenuOutlined />}
            </IconButton>
          </Container>
        </Toolbar>
      </AppBar>

      <Dialog
        maxWidth='sm'
        fullWidth
        open={openModal}
        PaperProps={{
          style: {
            backgroundColor: '#1A1D1F',
            borderRadius: '16px',
          },
        }}
      >
        <ClaimFaucetPopup onClose={() => setOpenModal(false)} />
      </Dialog>
    </>
  );
};

export default Header;
