import { AppBar, Button, Container, Toolbar } from '@mui/material';
import { useWindowSize } from 'hooks';

const Footer = () => {
  const { isMobile } = useWindowSize();
  return (
    <AppBar component='footer' position='static' className='bg-black/90'>
      <div
        className='border-y-[6px] border-[#ad9f8c]'
        style={{
          background: `url(${require('assets/images/Image.Footer.A.png')}) no-repeat center / cover`,
          zIndex: 1,
        }}
      >
        <Container className='h-[240px] relative flex items-center' maxWidth='xl'>
          <div className='flex flex-col w-full xl:w-auto items-center gap-y-4'>
            <div
              className='text-2xl sm:text-4xl font-title font-bold sm:px-20'
              style={{
                background: 'linear-gradient(180deg, #FFCE50 0%, #FFEFC6 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              OUR COMMUNITY
            </div>
            <div className='flex gap-6'>
              <Button
                className='hover:brightness-75 min-w-[52px] h-[52px] rounded-full'
                style={{ background: `url(${require('assets/icons/Icon.Youtube.png')})` }}
                href='https://www.youtube.com/watch?v=HmpuYs6ihgM'
                target='_blank'
                rel='noreferrer'
              />
              <Button
                className='hover:brightness-75 min-w-[52px] h-[52px] rounded-full'
                style={{ background: `url(${require('assets/icons/Icon.Discord.png')})` }}
                href='https://discord.gg/dragonarena'
                target='_blank'
                rel='noreferrer'
              />
              <Button
                className='hover:brightness-75 min-w-[52px] h-[52px] rounded-full'
                style={{ background: `url(${require('assets/icons/Icon.Twitter.png')})` }}
                href='https://twitter.com/The_DragonArena'
                target='_blank'
                rel='noreferrer'
              />
              <Button
                className='hover:brightness-75 min-w-[52px] h-[52px] rounded-full'
                style={{ background: `url(${require('assets/icons/Icon.Mirror.png')})` }}
                href='https://mirror.xyz/dragon-arena.eth'
                target='_blank'
                rel='noreferrer'
              />
            </div>
          </div>
          <img
            src={require('assets/icons/Icon.Dragon.A.png')}
            className='absolute right-32 bottom-[-40px] max-h-[340px] hidden xl:block'
          />
        </Container>
      </div>
      <Toolbar className='px-0'>
        <div
          className='flex flex-col items-center justify-center text-center gap-y-4 min-h-[164px] md:min-h-[385px] w-full'
          style={{
            background: `url(${require(`assets/images/${
              isMobile ? 'mobiles/Cover.JoinUs.webp' : 'Cover.JoinUs.webp'
            }`)}) no-repeat center / cover`,
          }}
        >
          <div className='font-bold text-white'>Want To Learn More?</div>
          <a
            href='https://discord.gg/dragonarena'
            target='_blank'
            rel='noreferrer'
            className='text-2xl sm:text-4xl font-bold font-title'
            style={{
              background: 'linear-gradient(180deg, #FFCE50 0%, #FFEFC6 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            JOIN US TODAY
          </a>
        </div>
      </Toolbar>
      <div className='font-medium text-sm text-white text-center py-10'>@DragonArena2023</div>
    </AppBar>
  );
};

export default Footer;
