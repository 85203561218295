import { useMemo } from 'react';
import { Banner, Feature, Gambling, Story, Utility, Partner } from './components';
import { detectBrowser } from 'utils/validate';

const Home = () => {
  const browser = useMemo(() => {
    return detectBrowser();
  }, []);

  return (
    <>
      <Banner browser={browser} />
      <Story browser={browser} />
      <Feature />
      <Gambling browser={browser} />
      <Utility />
      <Partner />
    </>
  );
};

export default Home;
