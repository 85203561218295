import { AppContainer } from 'containers';
import { PrivateLayout } from 'layouts';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { store } from 'reducers/store';
import AOS from 'aos';
import { useEffect } from 'react';

const App = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Provider store={store}>
      <AppContainer>
        <BrowserRouter>
          <Routes>
            <Route path='/*' element={<PrivateLayout />} />
          </Routes>
        </BrowserRouter>
      </AppContainer>
    </Provider>
  );
};

export default App;
