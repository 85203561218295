import { Container, Tab, Tabs } from '@mui/material';
import { useTabs, useWindowSize } from 'hooks';
import TabTopWinners from './components/TabTopWinners';
import TabTopRich from './components/TabTopRich';
import { profileSelector } from 'reducers/profileSlice';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { gameService } from 'services';
import { Spinner } from 'components';
import { formatNumber } from 'utils/common';
import moment from 'moment';

const LeaderBoard = () => {
  const { data, isFetching } = useQuery(['gameService.fetchTournaments'], () => gameService.fetchTournaments());

  const { address } = useSelector(profileSelector);

  const tabs = [
    {
      code: 'winners',
      label: 'TOP WINNERS',
      component: <TabTopWinners address={address!} tournamentId={data?.tournamentId} />,
    },
    { code: 'rich', label: 'TOP RICH', component: <TabTopRich address={address!} tournamentId={data?.tournamentId} /> },
  ];
  const [activeTab, onTabChange] = useTabs(tabs);

  const { isMobile } = useWindowSize();

  return (
    <Spinner loading={isFetching}>
      <div
        style={{ background: `url(${require('assets/images/Cover.LeaderBoard.png')}) no-repeat center / cover` }}
        className='py-16 md:py-28'
      >
        <Container maxWidth='xl' className='flex flex-col items-center justify-center'>
          <div className='text-center mb-6'>
            <div
              className='text-2xl sm:text-4xl font-bold font-title'
              style={{
                background: 'linear-gradient(180deg, #FFCE50 0%, #FFEFC6 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              TREASURY
            </div>
          </div>
          <div className='text-center mb-20'>
            <div className='flex items-center justify-center treasury mb-4'>
              <div className='flex justify-center items-center'>
                <img src={require('assets/images/Image.Treasury-Box.png')} />
                <div className='text'>
                  <div className='label'>Seasonal Treasury</div>
                  <div className='value'>
                    {data?.seasonalReward ? formatNumber(+data.seasonalReward + +data.seasonalFee) : '-'} DRA
                  </div>
                </div>
              </div>
            </div>
            <div className='flex justify-center items-center gap-x-10'>
              <div className='text'>
                <div className='label'>Seasonal Fee</div>
                <div className='value'>{data?.seasonalReward ? formatNumber(+data.seasonalFee) : '-'} DRA</div>
              </div>
              <div className='divider' />
              <div className='text'>
                <div className='label'>Seasonal Reward</div>
                <div className='value'>{data?.seasonalReward ? formatNumber(+data.seasonalReward) : '-'} DRA</div>
              </div>
            </div>
          </div>
          <div className='text-center mb-10'>
            <div
              className='text-2xl sm:text-4xl font-bold font-title'
              style={{
                background: 'linear-gradient(180deg, #FFCE50 0%, #FFEFC6 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              LEADERBOARD
            </div>
            <div className='flex items-center justify-center mt-4 gap-x-6'>
              Season 1 ({moment(data?.fromDate).utc().format('DD/MM/YYYY HH:mm')} UTC -{' '}
              {moment(data?.toDate).utc().format('DD/MM/YYYY HH:mm')} UTC)
            </div>
          </div>
          <div className={`flex items-center justify-center gap-x-5 ${isMobile ? 'container-scroll' : ''} pt-2`}>
            <img src={require('assets/images/Image.Dragon-Left.png')} className='hidden xl:block' />
            <div className='relative min-w-[375px]'>
              <img
                src={require(!isMobile
                  ? 'assets/images/Frame.LeaderBoard.png'
                  : 'assets/images/mobiles/Frame.LeaderBoard.png')}
              />
              <Tabs
                value={activeTab}
                onChange={onTabChange}
                textColor='inherit'
                classes={{ flexContainer: 'justify-center' }}
                className='absolute -inset-2'
                TabIndicatorProps={{
                  style: { display: 'none' },
                }}
              >
                {tabs.map((tab, index) => (
                  <Tab key={tab.code} label={tab.label} value={tab.code} />
                ))}
              </Tabs>
              {tabs
                .filter((tab) => tab.code === activeTab)
                .map((tab) => (
                  <div key={tab.code} className='absolute left-0 right-0 top-12'>
                    {tab.component}
                  </div>
                ))}
            </div>
            <img src={require('assets/images/Image.Dragon-Right.png')} className='hidden xl:block' />
          </div>
        </Container>
      </div>
    </Spinner>
  );
};

export default LeaderBoard;
