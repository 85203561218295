import { AppFooter, AppHeader } from 'containers';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { profileSelector } from 'reducers/profileSlice';
import { privateRoute } from 'routes';

const PrivateLayout = () => {
  const location = useLocation();
  const { isLoggedIn } = useSelector(profileSelector);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <AppHeader />
      <main className='pt-[80px] overflow-hidden'>
        <Routes>
          {Object.values(privateRoute)
            .filter(({ requiredLogin }: any) => !requiredLogin || isLoggedIn)
            .map(({ path, component: Element }) => (
              <Route key={path} path={path} element={<Element />} />
            ))}
          <Route path='*' element={<Navigate to={privateRoute.home.path} />} />
        </Routes>
      </main>
      <AppFooter />
    </>
  );
};

export default PrivateLayout;
