import { Chip, Container } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Spinner } from 'components';
import { useMemo } from 'react';
import { gameService } from 'services';
import { shorten, formatNumber } from 'utils/common';
import merge from 'utils/merge';
import Tooltip from '@mui/material/Tooltip';
import { useWindowSize } from 'hooks';
interface TabTopRichProps {
  address: string;
  tournamentId: string | undefined;
}

const TooltipRich = () => {
  return (
    <div>
      <div>
        Irrespective of the result of the match, the number of diamonds you can receive after each match: The amount of
        DRA you spend/1000 (rounded down to the nearest integer).
      </div>
      <div>Example: If you spend 2,600 DRA in one match, you will get 2 diamonds.</div>
      <div>
        <i>*Please note that matches in which you spend less than 1000 DRA will not be recorded.</i>
      </div>
    </div>
  );
};

const TabTopRich = ({ address, tournamentId }: TabTopRichProps) => {
  const { isMobile } = useWindowSize();

  const { data, isFetching } = useQuery(
    ['gameService.fetchTopRich', tournamentId],
    () => gameService.fetchTopRich(tournamentId),
    {
      enabled: !!tournamentId,
    },
  );

  const { data: winAmount } = useQuery(
    ['gameService.getWinAmount', { address, tournamentId }],
    () => gameService.getWinAmount({ address, tournamentId }),
    {
      enabled: !!address && !!tournamentId,
    },
  );

  const findIndex = useMemo(() => data?.findIndex((item) => item.address === address), [data, address]) ?? -1;

  return (
    <Container className='md:px-12'>
      <div className='relative'>
        <div className='text-sm text-center flex justify-center items-center'>
          List of players with the highest scores{' '}
          <Tooltip title={<TooltipRich />} placement='bottom'>
            <img src={require(`../../../assets/icons/tooltip.png`)} width={16} />
          </Tooltip>
        </div>
        <div className='flex items-center justify-between py-4 px-2 md:px-10'>
          <div className='text-center text-[#6F767E] min-w-[40px]'>Rank</div>
          <div className={`text-center text-[#6F767E] ${isMobile ? 'min-w-[130px]' : 'min-w-[165px]'}`}>Wallet</div>
          <div className={`text-center text-[#6F767E] ${isMobile ? 'min-w-[105px]' : 'min-w-[165px]'}`}>
            Diamonds Received
          </div>
          {!isMobile && <div className='text-center text-[#6F767E] min-w-[165px]'>Reward</div>}
        </div>
        <div className='h-[258px] container-scroll relative'>
          <Spinner loading={isFetching}>
            {data?.length! > 0 ? (
              data?.map((item, index) => (
                <div
                  className={merge('flex items-center justify-between py-4 px-2 md:px-10', {
                    'border-b border-[#374253]': index !== data?.length - 1,
                  })}
                  key={item.address}
                >
                  <div className='text-center min-w-[40px]'>
                    <div
                      style={{
                        background: index < 3 ? `url(${require(`assets/images/Image.Rank${index + 1}.png`)})` : '',
                      }}
                      className='w-[31px] h-[36px] flex items-center justify-center'
                    >
                      {index + 1}
                    </div>
                  </div>

                  <div className={`text-center ${isMobile ? 'min-w-[130px]' : 'min-w-[165px]'}`}>
                    {shorten(item.address)}
                  </div>
                  <div
                    className={`flex items-center justify-center text-center ${
                      isMobile ? 'min-w-[130px]' : 'min-w-[165px]'
                    }`}
                  >
                    {!!item?.volumePoints ? item?.volumePoints : '-'}{' '}
                    {!!item?.volumePoints && (
                      <img src={require(`../../../assets/icons/diamon.png`)} width={18} height={18} className='ml-1' />
                    )}
                  </div>
                  {!isMobile && (
                    <div className='text-center  min-w-[165px]'>
                      {item?.reward ? `${formatNumber(item.reward)} DRA` : '-'}{' '}
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className='flex flex-col items-center justify-center gap-y-3 mt-6 sm:mt-10'>
                <img src={require('assets/images/Image.Cup.png')} />
                <div className='text-sm text-white text-center'>
                  The Leaderboard will be updated soon.
                  <br />
                  Let’s play and rank up!
                </div>
              </div>
            )}
          </Spinner>
        </div>
        <div
          style={{ background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%)' }}
          className='h-16 absolute bottom-0 w-full'
        ></div>
      </div>

      {address ? (
        <div className='flex items-center justify-between bg-[#F4F4F433] py-4 px-2 md:px-10 mt-4 relative'>
          <Chip label='Your Rank' className='absolute -top-3 left-5 text-[#111315]' color='secondary' size='small' />
          <div className='text-center min-w-[40px]'>
            <div
              style={{
                background:
                  findIndex !== -1 && findIndex < 3
                    ? `url(${require(`assets/images/Image.Rank${findIndex + 1}.png`)})`
                    : '',
              }}
              className='w-[31px] h-[36px] flex items-center justify-center'
            >
              {findIndex > -1 ? findIndex + 1 : '-'}
            </div>
          </div>
          <div className={`text-center ${isMobile ? 'min-w-[130px]' : 'min-w-[165px]'}`}>
            {shorten(winAmount?.address) ?? '-'}
          </div>
          <div
            className={`flex items-center justify-center text-center ${isMobile ? 'min-w-[105px]' : 'min-w-[165px]'}`}
          >
            {!!winAmount?.volumePoints ? winAmount?.volumePoints : '-'}{' '}
            {!!winAmount?.volumePoints && (
              <img src={require(`../../../assets/icons/diamon.png`)} width={18} height={18} className='ml-1' />
            )}
          </div>
          {!isMobile && (
            <div className='text-center  min-w-[165px]'>
              {winAmount?.reward ? `${formatNumber(winAmount.reward)} DRA` : '-'}{' '}
            </div>
          )}
        </div>
      ) : (
        <div className='bg-[#F4F4F433] px-6 py-4 mt-4 lg:mt-9 text-center'>Connect wallet to see your rank.</div>
      )}
    </Container>
  );
};

export default TabTopRich;
